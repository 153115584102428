<script lang="ts" setup>
interface ListProps {
  requestApi: (...p: any) => any // 请求数据api
  requestError?: (...p: any) => any // 请求错误处理
  dataCallBack?: (...p: any) => any // 返回数据的回调函数，可以对数据进行处理
  requestParams: Record<string, any>
  offsetList: number | string
  loadingTextList: string
  finishedTextList: string
  errorTextList: string
  immediateCheckList: boolean
  disabledList: boolean
  directionList: 'down' | 'up'
  wrapClass: string
  headHeight?: number | string
  pullDistance?: number | string
}

const props = withDefaults(defineProps<ListProps>(), {
  requestParams: () => ({}), // 请求参数
  offsetList: 0, // 滚动条与底部距离小于 offset 时触发 load 事件number | string300
  loadingTextList: '加载中...', // 加载过程中的提示文案string加载中...
  finishedTextList: '加载完成，暂无更多', // 加载完成后的提示文案string-
  errorTextList: '获取失败，点击重新获取', // 加载失败后的提示文案string-
  immediateCheckList: true, // 是否在初始化时立即执行滚动位置检查boolean true
  disabledList: false, // 是否禁用滚动加载boolean false
  directionList: 'down', // 滚动触发加载的方向，可选值为 up
  wrapClass: '',
  headHeight: 50
})
// 下拉刷新是否处于加载中状态
const refreshing = ref(false)
// 上拉加载是否处于加载中状态
const loading = ref(false)
// 上拉加载失败
const error = ref(false)
// 是否已加载完成，加载完成后不再触发 load 事件
const finished = ref(false)
const pageParams = reactive({
  pageNum: 1, // 当前页
  pageSize: 10, // 每页数据条数
  total: 0 // 总页数
})
// 列表数据
const listData = ref([])
// 下拉刷新
function onRefresh() {
  handleReset()
  requestData(1)
}
// 上拉加载
function onLoad() {
  if (error.value) {
    return
  }
  requestData(2)
}
// 关闭加载状态
function loadStatus(isLoading: boolean) {
  refreshing.value = isLoading
  loading.value = isLoading
}

// 初始化
function handleReset() {
  pageParams.pageNum = 1
  finished.value = false
  error.value = false
}

// 监听请求参数是否发生变化
watch(
  () => props.requestParams,
  () => {
    onRefresh()
  }
)

// 当前是否在请求中
const isRequest = ref(false)
let cancelFn = () => {}

async function sendRequest(type: number, params: Record<string, any>) {
  try {
    isRequest.value = true
    const controller = new AbortController()
    const signal = controller.signal
    cancelFn = controller.abort
    const { data } = await props.requestApi(
      { ...pageParams, ...props.requestParams, ...params },
      {
        signal
      }
    )
    isRequest.value = false
    // debugger
    loadStatus(false)
    let requestData = data
    props.dataCallBack && (requestData = props.dataCallBack(requestData))
    if (requestData.record.length < pageParams.pageSize) {
      finished.value = true
    }
    if (type === 1) {
      listData.value = requestData.record
    } else {
      listData.value = listData.value.concat(requestData.record)
    }
    pageParams.pageNum++
    pageParams.pageSize = requestData.size || pageParams.pageSize
    pageParams.total = requestData.total || pageParams.total
  } catch (err: any) {
    isRequest.value = false
    if (err.code === 'ERR_CANCELED') {
      return
    }
    if (type === 2) {
      error.value = true
    }
    loadStatus(false)
    props.requestError && props.requestError(err)
  }
}
const debounceSendRequest = useDebounceFn(sendRequest, 300)

/*
 *@functionName: requestData
 *@params1: type    1 下拉刷新   2 上拉加载
 *@description:  加载列表数据
 */
function requestData(type: 1 | 2, params = {}) {
  if (!props.requestApi) {
    // eslint-disable-next-line no-throw-literal
    throw '请传入requestApi'
  }

  // 取消上次请求
  if (isRequest.value) {
    cancelFn()
  }
  loadStatus(true)
  debounceSendRequest(type, params)
}

function reLoad(params: Record<string, any>) {
  handleReset()
  requestData(1, params)
}

defineExpose({
  onRefresh,
  reLoad
})

const refreshList = ref<any>(null)

setInterval(() => {
  console.log(refreshList.value?.$el?.scrollTop)
}, 1000)
</script>

<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model:loading="loading"
      v-model:error="error"
      :finished="finished"
      :offset="props.offsetList"
      :loading-text="props.loadingTextList"
      :finished-text="props.finishedTextList"
      :error-text="props.errorTextList"
      :immediate-check="props.immediateCheckList"
      :disabled="props.disabledList"
      :direction="props.directionList"
      @load="onLoad"
    >
      <div v-if="listData.length > 0" :class="wrapClass">
        <template v-for="item in listData">
          <slot :data="item" />
        </template>
      </div>
      <van-empty description="暂无数据" v-else />
    </van-list>
  </van-pull-refresh>
</template>
